import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ContractInfo, QuotaInfo, UsageHistoryInfo, UserInfo } from "../../types/common";
import { userApi } from "../../api/userApi";
import dayjs from "dayjs";
import { local } from "../../helper/localStorage";

interface UserState {
  userInfo: UserInfo | null;
  quotaInfo: QuotaInfo | null;
  contractInfo: ContractInfo | null;
  usageHistory: UsageHistoryInfo | null;
  loading: boolean;
  error: string | null;
  loadingAdditionUserData: boolean;
}

const initialState: UserState = {
  userInfo: null,
  quotaInfo: null,
  contractInfo: null,
  usageHistory: null,
  loading: false,
  error: null,
  loadingAdditionUserData: false,
};

export const getInfoAccount = createAsyncThunk("customer/showInfo", async (_, { rejectWithValue }) => {
  try {
    const id = local.StorageGetSecure("id");
    const response = await userApi.getInfoAccount(id);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

export const updateInfoAccount = createAsyncThunk(
  "customer/updateInfo",
  async (data: UserInfo, { rejectWithValue }) => {
    try {
      const id = local.StorageGetSecure("id");
      const response = await userApi.updateInfoAccount(id, {
        ...data,
        birth_day: dayjs(data.birth_day).format("YYYY-MM-DD"),
      });
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const getQuotaInfo = createAsyncThunk("customer/getQuotaInfo", async (phone: string, { rejectWithValue }) => {
  try {
    const id = local.StorageGetSecure("id");
    const response = await userApi.getQuotaInfo(id, phone);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

export const getContractInfo = createAsyncThunk("customer/getContractInfo", async (_, { rejectWithValue }) => {
  try {
    const response = await userApi.getContractInfo();
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

export const getUsageHistory = createAsyncThunk("customer/getUsageHistory", async (data: any, { rejectWithValue }) => {
  try {
    const id = local.StorageGetSecure("id");
    const response = await userApi.getUsageHistory(id, data.phone, data.month);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => ({ ...state, ...action.payload }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInfoAccount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getInfoAccount.fulfilled, (state, action) => {
        state.loading = false;
        state.userInfo = action.payload;
      })
      .addCase(getInfoAccount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(updateInfoAccount.pending, (state) => {
        state.loadingAdditionUserData = true;
        state.loading = true;
        state.error = null;
      })
      .addCase(updateInfoAccount.fulfilled, (state, action) => {
        state.loadingAdditionUserData = false;
        state.loading = false;
        state.userInfo = action.payload.data;
      })
      .addCase(updateInfoAccount.rejected, (state, action) => {
        state.loadingAdditionUserData = false;
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(getQuotaInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getQuotaInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.quotaInfo = action.payload;
      })
      .addCase(getQuotaInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(getContractInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getContractInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.contractInfo = action.payload;
      })
      .addCase(getContractInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(getUsageHistory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUsageHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.usageHistory = action.payload;
      })
      .addCase(getUsageHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default userSlice.reducer;
export const { setUser } = userSlice.actions;
