import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import SIDEBAR_JA from "../locales/ja/sidebar.json";
import AUTH_JA from "../locales/ja/auth.json";
import COMMON_JA from "../locales/ja/common.json";
import DASHBOARD_JA from "../locales/ja/dashboard.json";
import CUSTOMER_INFO_JA from "../locales/ja/customer_info.json";
import USAGE_HISTORY_JA from "../locales/ja/usage_history.json";
import SIDEBAR_VI from "../locales/vi/sidebar.json";
import AUTH_VI from "../locales/vi/auth.json";
import COMMON_VI from "../locales/vi/common.json";
import DASHBOARD_VI from "../locales/vi/dashboard.json";
import CUSTOMER_INFO_VI from "../locales/vi/customer_info.json";
import USAGE_HISTORY_VI from "../locales/vi/usage_history.json";

export const languages = {
  ja: "Tiếng Nhật",
  vi: "Tiếng Việt",
} as const;

const resources = {
  ja: {
    sidebar: SIDEBAR_JA,
    auth: AUTH_JA,
    common: COMMON_JA,
    dashboard: DASHBOARD_JA,
    customer_info: CUSTOMER_INFO_JA,
    usage_history: USAGE_HISTORY_JA,
  },
  vi: {
    sidebar: SIDEBAR_VI,
    auth: AUTH_VI,
    common: COMMON_VI,
    dashboard: DASHBOARD_VI,
    customer_info: CUSTOMER_INFO_VI,
    usage_history: USAGE_HISTORY_VI,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "ja",
  ns: ["sidebar", "auth", "common", "dashboard", "service_info", "usage_history"],
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
