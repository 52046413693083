import CryptoJS from "crypto-js";
/**
 * @example setLocalStorage('xxx_key', 'xxx');
 * @example setLocalStorage('yyy_key', { yyy: 'yyy' });
 */
export const setLocalStorage = (key: string, value: string | { [key: string | number]: any }): void => {
  if (typeof window === "undefined") {
    return;
  }
  if (typeof value === "string") {
    window.localStorage.setItem(key, value);
  }
  window.localStorage.setItem(key, JSON.stringify(value));
};

/**
 * @example const xxx = getLocalStorage('xxx_key'); // typeof xxx -> 'string'
 * @example const yyy = getLocalStorage<YYY_Type>('yyy_key'); // typeof yyy -> Object
 */
export const getLocalStorage = <T = string>(key: string): null | string | T => {
  if (typeof window === "undefined") {
    return null;
  }
  const item = window.localStorage.getItem(key);
  if (item === null) {
    return null;
  }
  try {
    return JSON.parse(item);
  } catch {
    return item;
  }
};

/**
 * @example removeLocalStorage('xxx_key');
 */
export const removeLocalStorage = (key: string): void => {
  if (typeof window === "undefined") {
    return;
  }
  window.localStorage.removeItem(key);
};

export const local = {
  StorageSetSecure: (key: string, value: any) => {
    const data = CryptoJS.AES.encrypt(JSON.stringify(value), process.env.REACT_APP_HASH_KEY!).toString();
    localStorage.setItem(`${key}_um`, data);
  },
  StorageGetSecure: (key: string) => {
    const itemStr: string | null = localStorage.getItem(`${key}_um`);
    if (!itemStr) {
      return null;
    }
    const tmpData: CryptoJS.lib.WordArray = CryptoJS.AES.decrypt(
      itemStr === null || itemStr === undefined ? "" : itemStr,
      process.env.REACT_APP_HASH_KEY!,
    );
    const item = JSON?.parse(tmpData?.toString(CryptoJS.enc.Utf8));
    return item;
  },
  StorageRemoveSecure: (key: string) => {
    localStorage.removeItem(`${key}_um`);
  },
};
