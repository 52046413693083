import { Link } from "react-router-dom";
import image_logo from "../../assets/images/image_logo.png";
import { LayoutProps } from "../../types/common";
// import { useEffect, useState } from "react";
import { Menu, MenuProps } from "antd";
// import { useAppDispatch } from "../../redux/hook";
// import { changePassword, logout } from "../../redux/slices/authSlice";
import { useTranslation } from "react-i18next";
import Sider from "antd/es/layout/Sider";
import paths from "../../config";

export const Sidebar = ({ collapsed, setCollapsed }: LayoutProps) => {
  const { t } = useTranslation(["sidebar", "common"]);

  const menuItems: MenuProps["items"] = [
    {
      key: "dashboard",
      icon: (
        <span>
          <i className="fa-thin fa-chart-pie"></i>
        </span>
      ),
      label: <Link to={paths.dashboard}>{t("dashboard")}</Link>,
    },
    {
      key: "customer_info",
      icon: (
        <span>
          <i className="fa-thin fa-user"></i>
        </span>
      ),
      label: <Link to={paths.customer_info}>{t("common:thong_tin_khach_hang")}</Link>,
    },
    {
      key: "service_info",
      icon: (
        <span>
          <i className="fa-thin fa-file-signature"></i>
        </span>
      ),
      label: <Link to={paths.service_info}>{t("thong_tin_dich_vu")}</Link>,
    },
    {
      key: "usage_history",
      icon: (
        <span>
          <i className="fa-thin fa-history"></i>
        </span>
      ),
      label: <Link to={paths.usage_history}>{t("lich_su_su_dung")}</Link>,
    },
    //   {
    //     key: "status_usage",
    //     icon: (
    //       <span>
    //         <i className="fa-thin fa-circle-info"></i>
    //       </span>
    //     ),
    //     label: <Link to={paths.status_usage}>Status Usage</Link>,
    //   },
    //   {
    //     key: "usage_fee",
    //     icon: (
    //       <span>
    //         <i className="fa-thin fa-file-invoice-dollar"></i>
    //       </span>
    //     ),
    //     label: <Link to={paths.usage_fee}>Usage Fee</Link>,
    //   },
    //   {
    //     key: "promotion_campaign",
    //     icon: (
    //       <span>
    //         <i className="fa-thin fa-badge-percent"></i>
    //       </span>
    //     ),
    //     label: <Link to={paths.promotion_campaign}>Promotion Campaign</Link>,
    //   },
    //   {
    //     key: "support_info",
    //     icon: (
    //       <span>
    //         <i className="fa-thin fa-phone"></i>
    //       </span>
    //     ),
    //     label: <Link to={paths.support_info}>Support Info</Link>,
    //   },
    //   }
  ];
  return (
    <Sider trigger={null} collapsible collapsed={collapsed} collapsedWidth={0} theme="light" className="sidebar">
      <Link to="/" className="sidebar-logo">
        {/* <div className="sidebar-logo"> */}
        <img src={image_logo} alt="logo" />
        {/* </div> */}
      </Link>
      <Menu
        defaultSelectedKeys={["dashboard"]}
        items={menuItems}
        onSelect={() => setCollapsed && setCollapsed(window.innerWidth <= 992 ? true : false)}
        selectedKeys={[window.location.pathname.slice(1)]}
      />
    </Sider>
  );
};
