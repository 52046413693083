import { useEffect, useState } from "react";
// import { Header } from "../../components/Header";
import { Button, Collapse, Form, Input, Modal, Radio, Select, Tooltip } from "antd";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import { QuotaInfo, UserInfo } from "../../types/common";
import { getInfoAccount } from "../../redux/slices/userSlice";
import overLoader from "../../components/overLoader";
import { local } from "../../helper/localStorage";

export const ServiceInfo = () => {
  const [openSidebar, setOpenSidebar] = useState<boolean>(false);
  //   const [collapseSidebar, setCollapseSidebar] = useState<boolean>(false);
  const [openModalServiceInfo, setOpenModalServiceInfo] = useState<boolean>(false);
  const [openModalChangePackage, setOpenModalChangePackage] = useState<boolean>(false);
  const [openModalAddPackage, setOpenModalAddPackage] = useState<boolean>(false);
  const [openModalReissueSim, setOpenModalReissueSim] = useState<boolean>(false);
  const [openModalStorageSim, setOpenModalStorageSim] = useState<boolean>(false);

  // redux
  const dispatch = useAppDispatch();
  const userInfo: UserInfo = useAppSelector((state) => state.user.userInfo) as UserInfo;
  const quotaInfo: QuotaInfo = useAppSelector((state) => state.user.quotaInfo) as QuotaInfo;

  const token = local.StorageGetSecure("access_token");

  useEffect(() => {
    if (token) {
      dispatch(getInfoAccount());
    }
    // if (userInfo?.phone) {
    //   dispatch(getQuotaInfo(userInfo?.phone));
    // }
  }, [dispatch, token, userInfo?.phone]);

  const faqList = [
    {
      key: 1,
      label: (
        <div className="service-item-faq-question">
          <p className="service-item-faq-question-text">
            オ ー フ・ションサ ー ビ.スの追加・解約方法について
            <span className="service-item-faq-question-icon">
              <i className="fa-light fa-clone"></i>
            </span>
          </p>
        </div>
      ),
      children:
        "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Magnam necessitatibus a officiis suscipit saepe cum, nobis ipsum consequatur, corrupti doloremque voluptatem tenetur eius sequi sapiente nemo enim id, iure natus.",
    },
    {
      key: 2,
      label: (
        <div className="service-item-faq-question">
          <p className="service-item-faq-question-text">
            SIM カ一ド・eSIM の交換・再発彳テこ-つし、・て-
            <span className="service-item-faq-question-icon">
              <i className="fa-light fa-clone"></i>
            </span>
          </p>
        </div>
      ),
      children:
        "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Magnam necessitatibus a officiis suscipit saepe cum, nobis ipsum consequatur, corrupti doloremque voluptatem tenetur eius sequi sapiente nemo enim id, iure natus.",
    },
    {
      key: 3,
      label: (
        <div className="service-item-faq-question">
          <p className="service-item-faq-question-text">
            ェ ー タチマ・一-ジにつし、て
            <span className="service-item-faq-question-icon">
              <i className="fa-light fa-clone"></i>
            </span>
          </p>
        </div>
      ),
      children:
        "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Magnam necessitatibus a officiis suscipit saepe cum, nobis ipsum consequatur, corrupti doloremque voluptatem tenetur eius sequi sapiente nemo enim id, iure natus.",
    },
  ];

  return (
    <div className="wrapper service">
      {userInfo ? "" : overLoader()}
      {/* <Sidebar
        openSidebar={openSidebar}
        collapseSidebar={collapseSidebar}
        handleCollapseSidebar={() => setCollapseSidebar(!collapseSidebar)}
      /> */}
      <div className="main">
        {/* <Header handleToggleSidebar={() => setOpenSidebar(!openSidebar)} /> */}
        <div className="main-content">
          <div className="service-item">
            <div className="service-item-info">
              <h1 className="service-item-info-phone">{userInfo?.phone || "––"}</h1>
              <div className="service-item-info-package">
                <span className="service-item-info-package-name">{quotaInfo?.planCode || "––"}</span>
                {/* <span className="service-item-info-package-icon">
                  <i className="fa-light fa-circle-info"></i>
                </span> */}
                <Tooltip title="Thông tin gói cước">
                  <span className="service-item-info-package-icon" onClick={() => setOpenModalServiceInfo(true)}>
                    <i className="fa-light fa-circle-info"></i>
                  </span>
                </Tooltip>
                <Modal
                  title="Thông tin gói cước"
                  open={openModalServiceInfo}
                  onCancel={() => setOpenModalServiceInfo(false)}
                >
                  <span>Thông tin gói cước</span>
                </Modal>
              </div>
              <span className="service-item-info-rate">1,078 円一3,278 円/月額</span>
              <span className="service-item-info-text">安心、・便利な機能が勢揃い!モノ(イノし生活がさらに充実。</span>
              <div className="service-item-info-action">
                <div className="package-action">
                  {new Date().getDate() < 15 ? (
                    <button className="package-action-button" onClick={() => setOpenModalChangePackage(true)}>
                      プラン変更
                    </button>
                  ) : (
                    <button className="package-action-button" disabled>
                      プラン変更
                    </button>
                  )}
                  <Modal
                    title="プラン変更"
                    open={openModalChangePackage}
                    onCancel={() => setOpenModalChangePackage(false)}
                    className="package-action-modal"
                    okText="Save"
                  >
                    <span className="package-action-text">Lựa chọn gói cước muốn đổi</span>
                    <Radio.Group className="package-action-radio" buttonStyle="solid">
                      <Radio.Button value={1} className="package-action-radio-button">
                        Rakuten是強プラン
                      </Radio.Button>
                      <Radio.Button value={2} className="package-action-radio-button">
                        Rakuten是強プラン
                      </Radio.Button>
                      <Radio.Button value={3} className="package-action-radio-button">
                        Rakuten是強プラン
                      </Radio.Button>
                    </Radio.Group>
                  </Modal>
                </div>
                <div className="package-action">
                  <button className="package-action-button" onClick={() => setOpenModalAddPackage(true)}>
                    オプション追加
                  </button>
                  <Modal
                    title="オプション追加"
                    open={openModalAddPackage}
                    onCancel={() => setOpenModalAddPackage(false)}
                    className="package-action-modal"
                    okText="Save"
                  >
                    <span className="package-action-text">Lựa chọn gói cước muốn đăng kí thêm</span>
                    <Radio.Group className="package-action-radio" buttonStyle="solid">
                      <Radio.Button value={1} className="package-action-radio-button">
                        5 phút
                      </Radio.Button>
                      <Radio.Button value={2} className="package-action-radio-button">
                        10 phút
                      </Radio.Button>
                      <Radio.Button value={3} className="package-action-radio-button">
                        15 phút
                      </Radio.Button>
                    </Radio.Group>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
          <div className="service-item">
            <div className="service-item-sim">
              <h1 className="service-item-sim-title">各重手続き</h1>
              <div className="service-item-sim-reissue">
                <h2 className="service-item-sim-reissue-title">SIM再発彳テ</h2>
                <p className="service-item-sim-reissue-text">紛失・そのイ也玉里由の SIM 再発彳ラのお手キ売き</p>
                <button className="service-item-sim-reissue-button" onClick={() => setOpenModalReissueSim(true)}>
                  SIM再発行を申請する
                </button>
                <Modal
                  title="SIM再発彳テ"
                  open={openModalReissueSim}
                  onCancel={() => setOpenModalReissueSim(false)}
                  footer={null}
                  className="reissue-modal"
                >
                  {/* <span className="reissue-text">Lựa chọn sim muốn phát hành lại</span> */}
                  <Form layout="vertical" className="reissue-form">
                    <Form.Item label="Lựa chọn loại sim muốn phát hành lại" required className="reissue-form-item">
                      <Radio.Group buttonStyle="solid">
                        <Radio.Button value={1}>Sim vật lí</Radio.Button>
                        <Radio.Button value={2}>eSim</Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item label="Địa chỉ" required className="reissue-form-item">
                      <Input placeholder="Nhập địa chỉ nhận" />
                    </Form.Item>
                    <Form.Item className="reissue-form-item reissue-form-button">
                      <Button className="reissue-form-button-cancel" onClick={() => setOpenModalReissueSim(false)}>
                        Cancel
                      </Button>
                      <Button type="primary" htmlType="submit" className="reissue-form-button-submit">
                        Save
                      </Button>
                    </Form.Item>
                  </Form>
                </Modal>
              </div>
              <div className="service-item-sim-storage">
                <h2 className="service-item-sim-storage-title">そのイ也のお手続き</h2>
                <p className="service-item-sim-storage-text">
                  他ネ土、のお乗り攅、え- (MNP) ・禾」.用イ事」と・角翆第勺び)お手キ売きカよど
                </p>
                <button className="service-item-sim-storage-button" onClick={() => setOpenModalStorageSim(true)}>
                  電言舌番号保管
                </button>
                <Modal
                  title="電言舌番号保管"
                  open={openModalStorageSim}
                  onCancel={() => setOpenModalStorageSim(false)}
                  className="storage-modal"
                >
                  <Form layout="vertical" className="storage-form">
                    <Form.Item label="Lựa chọn số tháng muốn lưu trữ" required className="storage-form-item">
                      <Select defaultValue="Lựa chọn số tháng muốn lưu trữ">
                        <Select.Option value="1">3 tháng</Select.Option>
                        <Select.Option value="2">4 tháng</Select.Option>
                        <Select.Option value="3">5 tháng</Select.Option>
                        <Select.Option value="4">6 tháng</Select.Option>
                        <Select.Option value="5">7 tháng</Select.Option>
                        <Select.Option value="6">8 tháng</Select.Option>
                        <Select.Option value="7">9 tháng</Select.Option>
                        <Select.Option value="8">10 tháng</Select.Option>
                        <Select.Option value="9">11 tháng</Select.Option>
                        <Select.Option value="10">12 tháng</Select.Option>
                      </Select>
                    </Form.Item>
                    <p className="storage-form-text">Thời gian bắt đầu tính từ tháng tiếp theo</p>
                    <div className="storage-form-price">
                      <span className="storage-form-price-text">Cước phí: </span>
                      <span className="storage-form-price-number">1,078 円</span>
                    </div>
                  </Form>
                </Modal>
              </div>
            </div>
          </div>
          <div className="service-item">
            <div className="service-item-faq">
              <h1 className="service-item-faq-title">よくあるこ、質問</h1>
              <div className="service-item-faq-list">
                <Collapse items={faqList} accordion ghost className="service-item-faq-accordion" />
              </div>
            </div>
            <div className="service-item-support">
              <Link to="/support" className="service-item-support-link">
                サポート画面から質問を探す
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className={`overlay${openSidebar ? " active" : ""}`} onClick={() => setOpenSidebar(!openSidebar)}></div>
    </div>
  );
};
